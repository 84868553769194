<template>
  <div class="container">
    <PublicHeader :totalName="$t('bank').DigitalCurrency" />
      <van-field
        v-model="form.USDT"
        label="USDT"
        :placeholder="$t('bank').usdt"
      />
      <van-field v-model="form.BTC" label="BTC" :placeholder="$t('bank').btc" />
      <van-field v-model="form.ETH" label="ETH" :placeholder="$t('bank').eth" />
      <i></i>
    <van-button size="large" @click="handleSubmit">{{
      $t('bank').submit
    }}</van-button>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      value: '',
      show: false,
      columns: [],
      bankName: '',
      defaultIndex: 0,
      form: {
        USDT: '',
        BTC: '',
        ETH: '',
      },
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    async getUserInfo() {
      let res = await userApi.userInfo()
      for (let i in this.form) {
        this.form[i] = res[i]
      }
    },
    async handleSubmit() {
      let form = { ...this.form }
      await userApi.UpdateAddress(form)
      this.$toast.success(this.$t('public').SubmittedSuccessfully)
      this.$router.go(-1)
    },
  },
  mounted() {
    this.getUserInfo()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #fae39c
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .van-field
    margin 0
    background none
    >>> .van-field__label
      color #ebe5cd
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .van-button
    width calc(100% - 30px)
    border none
    margin 21px 15px 23px
    color #000
    font-size 14px
    height 44px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important
  .van-picker
    color #000
</style>
